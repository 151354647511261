<template>
  <div class="interaction-menu" :style="themeVars">
    <div v-if="!isUnFold" v-for="item in menuBar" :key="item.index"
      :class="activeKey === item.index ? 'menu-item-active' : 'menu-item'" @click="changeActiveKey(item.index)">
      <svg class="menu-item-icon">
        <use :xlink:href="activeKey === item.index ? item.active : item.default"></use>
      </svg>
      <div :class="activeKey === item.index ? 'menu-item-name-active' : 'menu-item-name'">{{ item.title }}</div>
    </div>
    <div class="unfold">
      <div v-if="!isUnFold" :class="cureNoteDetail.toothViewVersion === '0' ? 'yellow-icon' : 'default-icon'" @click="changeType"></div>
      <img v-else class="rotate-unfold" src="@/common/imgs/exocad_active.png" alt="" @click="changeType">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    themeVars: Object,
    activeKey: Number,
    cureType: String,
    isUnFold: Boolean,
    menuBar: Array,
    cureNoteDetail: Object
  },

  methods: {
    changeType() {
      const _isUnFold = !this.isUnFold;
      this.$emit('changeType', _isUnFold);
    },
    changeActiveKey(e) {
      this.$emit('changeKey', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.interaction-menu {
  width: 100%;
  height: 68px;
  background-color: var(--darkColor);
  display: flex;
  justify-content: center;
  align-items: center;

  .menu-item,
  .menu-item-active {
    cursor: pointer;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .menu-item-icon {
      width: 32px;
      height: 32px;
    }

    .menu-item-name,
    .menu-item-name-active {
      font-size: 14px;
      color: var(--messageColor);
    }

    .menu-item-name-active {
      color: var(--mainColor);
    }

  }

  .menu-item-active {
    background-color: var(--backgroundColor);
  }

  .unfold {
    width: 72px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .yellow-icon,
    .default-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .yellow-icon {
      background: var(--pack-up-menu-img) no-repeat center;
      background-size: 100% 100%;
    }

    .default-icon {
      background: var(--unfold-menu-img) no-repeat center;
      background-size: 100% 100%;
    }

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    .rotate-unfold {
      transform: rotateZ(180deg);
    }

  }

}
</style>