var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dentition-space"},[(_vm.cureType === 'C')?_c('div',{staticClass:"upjaw-container"},_vm._l((_vm.teethOne),function(item,index){return _c('div',{key:index,class:[
        _vm.selectTeethFn(item.number, _vm.selectOne)
          ? 'jaw-item'
          : 'jaw-item-disabled',
        item.width && 'jaw-item-changed'
      ]},[_c('span',[_vm._v(_vm._s(item.number))]),_c('el-input',{staticClass:"space-input",attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectOne),"value":item.width,"type":"text"},on:{"change":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectOne
          })},"input":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectOne
          })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}})],1)}),0):_c('div',{staticClass:"upjaw-container"},_vm._l((_vm.teethTow),function(item,index){return _c('div',{key:index,class:[
        _vm.selectTeethFn(item.number, _vm.selectTow)
          ? 'jaw-item'
          : 'jaw-item-disabled',
        item.width && 'jaw-item-changed'
      ]},[_c('span',[_vm._v(_vm._s(item.number))]),_c('el-input',{staticClass:"space-input",attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectTow),"value":item.width,"type":"text"},on:{"change":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectTow
          })},"input":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectTow
          })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}})],1)}),0),(_vm.cureType === 'C')?_c('div',{staticClass:"lowerjaw-container"},_vm._l((_vm.teethFour),function(item,index){return _c('div',{key:index,class:[
        _vm.selectTeethFn(item.number, _vm.selectFour)
          ? 'jaw-item'
          : 'jaw-item-disabled',
        item.width && 'jaw-item-changed'
      ]},[_c('span',[_vm._v(_vm._s(item.number))]),_c('el-input',{staticClass:"space-input",attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectFour),"value":item.width,"type":"text"},on:{"change":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectFour
          })},"input":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectFour
          })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}})],1)}),0):_c('div',{staticClass:"lowerjaw-container"},_vm._l((_vm.teethThree),function(item,index){return _c('div',{key:index,class:[
        _vm.selectTeethFn(item.number, _vm.selectThree)
          ? 'jaw-item'
          : 'jaw-item-disabled',
        item.width && 'jaw-item-changed'
      ]},[_c('span',[_vm._v(_vm._s(item.number))]),_c('el-input',{staticClass:"space-input",attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectThree),"value":item.width,"type":"text"},on:{"change":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectThree
          })},"input":function($event){return _vm.disposeNumber({
            key: item,
            value: arguments[0],
            selectList: _vm.selectThree
          })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }