var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.tabsKey === 3 ? 'information-large' : 'information'},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"label"},[_c('svg',{staticClass:"label-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-gerenziliao_xuanzhong"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("threeD.blzl")))])]),_c('i',{staticClass:"el-icon-close close-icon",on:{"click":_vm.closeDrawer}})]),_c('div',{staticClass:"tabs-menu"},_vm._l((_vm.tabsList),function(item,index){return _c('div',{key:index,class:_vm.tabsKey === index ? 'tabs-item-active' : 'tabs-item',on:{"click":function($event){return _vm.changeTabsKey(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),(!_vm.isLoading)?_c('div',{staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabsKey === 0),expression:"tabsKey === 0"}],staticClass:"patient_pic_wrap"},[(_vm.patientPictureList.length > 0)?_vm._l((_vm.patientPictureList),function(patient,pax){return _c('div',{key:pax,staticClass:"patient_pic curp"},[_c('img',{staticClass:"patient_pic_item",style:({
              transform: ("rotate(" + (patient['degree']) + "deg) scaleX(" + (patient['horizontal'] ? -1 : 1) + ") scaleY(" + (patient['vertical'] ? -1 : 1) + ")"),
            }),attrs:{"src":("" + _vm.$PicPrefix + (patient['nanoId']))},on:{"click":function($event){_vm.showPicFn(patient.name, _vm.getPicIndex(patient.filed))}}}),_c('p',{staticClass:"tac"},[_vm._v(_vm._s(patient.name))])])}):_c('div',{staticClass:"mon_img"},[_c('img',{attrs:{"src":_vm.noPicFn(),"alt":""}}),_c('span',{staticClass:"init_text"},[_vm._v(_vm._s(_vm.$t("threeD.zsmytp")))])])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabsKey === 1),expression:"tabsKey === 1"}],staticClass:"patient_pic_wrap"},[(_vm.xLightPictureList.length > 0)?_vm._l((_vm.xLightPictureList),function(item,index){return _c('div',{key:index,staticClass:"patient_pic curp"},[_c('img',{staticClass:"patient_pic_item",style:({
              transform: ("rotate(" + (item['degree']) + "deg) scaleX(" + (item['horizontal'] ? -1 : 1) + ") scaleY(" + (item['vertical'] ? -1 : 1) + ")"),
            }),attrs:{"src":("" + _vm.$PicPrefix + (item['nanoId']))},on:{"click":function($event){_vm.showPicFn(item.name, _vm.getPicIndex(item.filed))}}}),_c('p',{staticClass:"tac"},[_vm._v(_vm._s(item.name))])])}):_c('div',{staticClass:"mon_img"},[_c('img',{attrs:{"src":_vm.noPicFn(),"alt":""}}),_c('span',{staticClass:"init_text"},[_vm._v(_vm._s(_vm.$t("threeD.zsmytp")))])])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabsKey === 2),expression:"tabsKey === 2"}],staticClass:"patient_pic_wrap"},[(_vm.otherPictureList.length > 0)?_vm._l((_vm.otherPictureList),function(item,index){return _c('div',{key:index,staticClass:"patient_pic curp"},[_c('img',{staticClass:"patient_pic_item",style:({
              transform: ("rotate(" + (item['degree']) + "deg) scaleX(" + (item['horizontal'] ? -1 : 1) + ") scaleY(" + (item['vertical'] ? -1 : 1) + ")"),
            }),attrs:{"src":("" + _vm.$PicPrefix + (item.nanoId))},on:{"click":function($event){return _vm.showPicFn(item.name, item.index)}}}),_c('p',{staticClass:"tac"},[_vm._v(_vm._s(item.name))])])}):_c('div',{staticClass:"mon_img"},[_c('img',{attrs:{"src":_vm.noPicFn(),"alt":""}}),_c('span',{staticClass:"init_text"},[_vm._v(_vm._s(_vm.$t("threeD.zsmytp")))])])],2),_c('oper-preview',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.tabsKey === 3 && _vm.cureNoteDetail.id && _vm.diagnosisDetail.type !== '4'
      ),expression:"\n        tabsKey === 3 && cureNoteDetail.id && diagnosisDetail.type !== '4'\n      "}],attrs:{"isOldCase":_vm.get(_vm.cureNoteDetail, 'toothViewVersion') === '0',"selectedTabKey":"3","diagnosisDetail":_vm.diagnosisDetail,"details":Object.assign({}, _vm.cureNoteDetail, _vm.caseDetail, _vm.diagnosisDetail),"caseInfo":_vm.caseDetail,"userInfo":_vm.caseDetail['userInfo'],"teethKeyObj":_vm.teethKeyObj,"babyTeethKeyObj":_vm.babyTeethKeyObj,"commonTeeth":_vm.commonTeeth,"uploadIntervalList":_vm.uploadIntervalList,"crowdTeethList":_vm.crowdTeethList,"commonVeneerList":_vm.commonVeneerList,"curePlanDetail":_vm.caseDetail['curePlanInfo']}}),(
        _vm.tabsKey === 3 && _vm.cureNoteDetail.id && _vm.diagnosisDetail.type === '4'
      )?_c('div',{staticClass:"stage-adjust-preview"},[_c('stageAdjustmentPreview',{attrs:{"dictObj":_vm.dictObj,"caseDetail":_vm.caseDetail,"diaKeyObj":_vm.diaKeyObj,"previewData":_vm.diagnosisDetail,"isDatabase":true,"previewCom":['table']}})],1):_vm._e()],1):_vm._e(),_c('PreviewImg',{ref:"previewImg",attrs:{"carouselList":_vm.carouselList},on:{"imgChange":_vm.imgChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }