<template>
  <div
    :class="isUnFold ? 'unfold-interaction' : 'interaction'"
    :style="themeVars"
  >
    <InteractionMenu
      :cureType="caseDetail['cureType']"
      :cureNoteDetail="cureNoteDetail"
      :themeVars="themeVars"
      :menuBar="menuBar"
      :activeKey="activeKey"
      @changeKey="e => (activeKey = e)"
      :isUnFold="isUnFold"
      @changeType="changeElmType"
    />
    <div
      v-if="!isUnFold"
      :class="
        activeKey === 2 || (activeKey === 3 && informationTabsKey === 3)
          ? 'interaction-content-large'
          : 'interaction-content'
      "
    >
      <Feedback
        v-show="activeKey === 0"
        :canEdit="canEdit"
        :mod3DRecord="mod3DRecord"
        :userDetail="userDetail"
        :cureNoteDetail="cureNoteDetail"
        :isEdit="isEdit"
        :caseDetail="caseDetail"
        :environmene="environmene"
        :plasticsList="plasticsList"
        :plasticSheetObj="plasticSheetObj"
        @stagingData="stagingData"
        @changeViewType="changeViewType"
        :defaultOperateRemark="defaultOperateRemark"
        :defaultVeneerRemark="defaultVeneerRemark"
        :defaultAmendOpinion="defaultAmendOpinion"
        @changeVeneerRemark="e => (defaultVeneerRemark = e)"
        @changeOperateRemark="e => (defaultOperateRemark = e)"
        @changeAmendOpinionShow="e => (defaultAmendOpinion = e)"
      />
      <History
        v-if="activeKey === 1"
        :canEdit="canEdit"
        :canCompare="canCompare"
        :isEdit="isEdit"
        @closeDrawer="activeKey = 0"
        @updateComparePlanList="updateComparePlanList"
        @historyCompare="historyCompare"
      />
      <OrthodonticInstructions
        v-if="activeKey === 2"
        :cureNoteDetail="cureNoteDetail"
        :caseDetail="caseDetail"
        @closeDrawer="activeKey = 0"
      />
      <Information
        v-if="activeKey === 3"
        :caseDetail="caseDetail"
        :cureNoteDetail="cureNoteDetail"
        :diagnosisDetail="diagnosisDetail"
        :tabsKey="informationTabsKey"
        @changeTabsKey="e => (informationTabsKey = e)"
        @closeDrawer="activeKey = 0"
      />
    </div>
    <div v-else class="unfold-menu">
      <div
        v-for="(item, index) in menuBar"
        :key="index"
        class="unfold-menu-item"
        @click="showDialogDrag(index)"
      >
        <svg class="menu-item-icon">
          <use
            :xlink:href="activeKey === item.index ? item.active : item.default"
          ></use>
        </svg>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible"
      @close="dialogVisible = false"
      :title="activeKey !== null ? menuBar[activeKey].title : ''"
      :visible="dialogVisible"
      :modal="false"
      :class="
        activeKey === 2 || (activeKey === 3 && informationTabsKey === 3)
          ? 'diag-interaction-large'
          : 'diag-interaction'
      "
      v-dialogDrag
    >
      <div class="drag-content">
        <Feedback
          v-show="activeKey === 0"
          :canEdit="canEdit"
          :mod3DRecord="mod3DRecord"
          :userDetail="userDetail"
          :cureNoteDetail="cureNoteDetail"
          :isEdit="isEdit"
          :caseDetail="caseDetail"
          :environmene="environmene"
          :plasticsList="plasticsList"
          :plasticSheetObj="plasticSheetObj"
          @stagingData="stagingData"
          @changeViewType="changeViewType"
          :defaultOperateRemark="defaultOperateRemark"
          :defaultVeneerRemark="defaultVeneerRemark"
          :defaultAmendOpinion="defaultAmendOpinion"
          @changeVeneerRemark="e => (defaultVeneerRemark = e)"
          @changeOperateRemark="e => (defaultOperateRemark = e)"
          @changeAmendOpinionShow="e => (defaultAmendOpinion = e)"
        />
        <History
          v-if="activeKey === 1"
          :canEdit="canEdit"
          :canCompare="canCompare"
          :isEdit="isEdit"
          @closeDrawer="activeKey = 0"
          @updateComparePlanList="updateComparePlanList"
          @historyCompare="historyCompare"
        />
        <OrthodonticInstructions
          v-if="activeKey === 2"
          :cureNoteDetail="cureNoteDetail"
          :caseDetail="caseDetail"
          @closeDrawer="activeKey = 0"
        />
        <Information
          v-if="activeKey === 3"
          :caseDetail="caseDetail"
          :cureNoteDetail="cureNoteDetail"
          :diagnosisDetail="diagnosisDetail"
          :tabsKey="informationTabsKey"
          @changeTabsKey="e => (informationTabsKey = e)"
          @closeDrawer="activeKey = 0"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import InteractionMenu from "./interaction-menu.vue";
import OrthodonticInstructions from "./orthodontic-instructions.vue";
import Information from "./information.vue";
import Feedback from "./feedback.vue";
import History from "./history.vue";

export default {
  props: {
    themeVars: Object,
    environmene: String,
    cureNoteDetail: Object,
    caseDetail: Object,
    plasticsList: Array,
    plasticSheetObj: Object,
    diagnosisDetail: Object,
    userDetail: Object,
    mod3DRecord: String,
    canEdit: Boolean,
    canCompare: Boolean,
    isEdit: Boolean
  },
  components: {
    InteractionMenu,
    Feedback,
    History,
    OrthodonticInstructions,
    Information
  },
  data() {
    return {
      toothViewVersion: "0",
      activeKey: 0,
      informationTabsKey: 0,
      isUnFold: false,
      dialogVisible: false,
      defaultOperateRemark: "",
      defaultVeneerRemark: "",
      defaultAmendOpinion: false,
      menuBar: [
        {
          title: this.$t("threeD.fk"),
          index: 0,
          active: "#icon-yijianfankui_xuanzhong",
          default: "#icon-yijianfankui_moren"
        },
        {
          title: this.$t("threeD.lsfa"),
          index: 1,
          active: "#icon-lishi_xuanzhong",
          default: "#icon-lishi_moren"
        },
        {
          title: this.$t("threeD.jzsm"),
          index: 2,
          active: "#icon-shuoming_xuanzhong",
          default: "#icon-shuoming_moren"
        },
        {
          title: this.$t("threeD.blzl"),
          index: 3,
          active: "#icon-gerenziliao_xuanzhong",
          default: "#icon-gerenziliao_moren"
        }
      ].filter(item => Object.keys(item).length)
    };
  },
  computed: {
    kind() {
      return this.userDetail["kind"];
    }
  },
  methods: {
    updateComparePlanList(comparePlanList) {
      this.$emit("updateComparePlanList", comparePlanList);
    },
    historyCompare(id) {
      this.$emit("historyCompare", id);
    },
    showDialogDrag(idx) {
      this.dialogVisible = true;
      this.activeKey = idx;
    },
    changeElmType(data) {
      this.isUnFold = data;
      this.dialogVisible = false;
    },
    changeViewType(data) {
      this.$emit("changeViewType", data);
    },
    stagingData(type, isAutoSave) {
      this.$emit("stagingData", type, isAutoSave);
    }
  },
  mounted() {
    const { toothViewVersion } = this.cureNoteDetail;
    this.toothViewVersion = toothViewVersion;
  }
};
</script>

<style lang="scss" scoped>
.unfold-interaction,
.interaction,
.interaction-position {
  width: 444px;
  height: 100%;
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  position: relative;

  .interaction-content,
  .interaction-content-large {
    flex: 1;
    width: 100%;
    position: absolute;
    right: 0;
    top: 68px;
    height: calc(100% - 68px);
    overflow: hidden;
  }

  .interaction-content-large {
    background-color: var(--backgroundColor);
    width: 1360px;
    z-index: 10;
  }
}

.interaction-position {
  position: absolute;
  right: 0;
  top: 0;
  width: 23%;
  height: 90.4%;
}

.unfold-menu {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &-item {
    width: 100%;
    margin-bottom: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu-item-icon {
      width: 32px;
      height: 32px;
    }
  }
}

.diag-interaction-large,
.diag-interaction {
  /deep/ .el-dialog {
    width: 444px;
  }

  .drag-content {
    background-color: var(--backgroundColor);
    height: 800px;
    overflow: hidden;
  }
}

.unfold-interaction {
  width: 64px;
}

/deep/ .diag-interaction,
.diag-interaction-large {
  width: auto !important;
  left: unset;
  bottom: unset;
  overflow: unset;
  margin: unset;
  height: 0px;

  .el-dialog {
    margin-top: 0px !important;
    width: 444px;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--backgroundColor);
    position: absolute;
    right: 65px;
    top: 68px;
  }

  .drag-content {
    height: 700px;
    overflow: hidden;
  }
}

/deep/ .diag-interaction-large {
  .el-dialog {
    width: 1160px;
    margin-top: 0px !important;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--backgroundColor);
    position: absolute;
    right: 65px;
    top: 68px;
  }
}

/deep/ .el-dialog__header {
  position: absolute;
  width: 100%;
  background-color: var(--backgroundColor);
  height: 50px;
  z-index: 1;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-dialog__title {
    font-size: 16px;
    color: var(--textColor) !important;
  }

  .el-dialog__headerbtn {
    position: unset;

    > i {
      color: var(--textColor);
    }
  }
}

/deep/ .el-dialog__body {
  padding: 10px 0 0;
}
</style>
