<template>
  <div class="orthobuilder">
    <div class="uploading" v-if="uploading">
      <div class="uploading-content">
        <img
          src="https://picture.aismile.cn/web-static/aismile.cn/file-uploading.gif"
          alt=""
        />
        <span>Loading...</span>
      </div>
    </div>
    <iframe
      id="ortho"
      :src="iFrameSrc"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import cryptoJS from "crypto-js";
import { nanoid } from "nanoid";
import axios from "axios";
import { threeDesignPlanModDetail } from "common/api/cases";
import { getQiniuToken } from "common/api/public";

export default {
  props: {
    environmene: String,
    cureNoteDetail: Object,
    caseDetail: Object,
    plasticsList: Array,
    plasticSheetObj: Object,
    toothViewData: Object,
    userDetail: Object,
    modData: Object,
    comparePlanList: Array,
    themeDetail: Object
  },
  data() {
    return {
      host3d: "",
      ortho: null,
      iFrameSrc: "",
      uploadToken: "",
      hideRight: false,
      uploading: false,
      attInfo: {},
      isEdit: false,
      isLoaded: false,
      isAutoSave: false,
      nowCompareId: ""
    };
  },
  watch: {
    isEdit(newval) {
      if (newval && !this.isLoaded) {
        let { token } = this.$route.query;
        const { id, threeDimensionalDesignPlanId } = this.cureNoteDetail;
        const hasModId =
          (this.nowPort === "RDT" ? "rdtMod3DId" : "doctorMod3DId") in
          this.cureNoteDetail;
        if (hasModId && ["DOCTOR", "RDT"].includes(this.nowPort)) {
          threeDesignPlanModDetail(
            token
              ? {
                  cureNoteId: id,
                  threeDimensionalDesignPlanId,
                  token
                }
              : {
                  cureNoteId: id,
                  threeDimensionalDesignPlanId
                }
          ).then(data => {
            this.load(data.key);
          });
        }
      }
    }
  },
  computed: {
    nowPort() {
      return this.userDetail["kind"] || "DOCTOR";
    },
    amendmentShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      const irdtCanShow = [
        "to_approve_plan",
        "to_reconfirm_plan",
        "to_confirm_plan"
      ];
      const canShow = ["to_doctor_confirm_plan"];
      if (
        this.nowPort === "RDT" &&
        irdtCanShow.includes(status) &&
        ["0", "2"].includes(this.cureNoteDetail["passFlag"]) &&
        this.cureNoteDetail["generateFlag"] == "1" &&
        this.cureNoteDetail["historyFlag"] == "0"
      ) {
        return true;
      }
      if (["SALES", "LIANSUO_CLINIC", "DOCTOR"].includes(this.nowPort)) {
        if (canShow.includes(status)) {
          return true;
        }
      }
    },
    approveShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      if (status == "to_doctor_confirm_plan" && this.nowPort === "DOCTOR") {
        return true;
      }
    },
    buttonShow() {
      if (this.cureNoteDetail["historyFlag"] === "0") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    init() {
      const { toothViewVersion, toothView, arches } = this.cureNoteDetail;
      const host3d =
        this.environmene === "online"
          ? "https://3d.aismile.cn"
          : `http://3d.${this.environmene}.aismile.cn`;
      // const host3d = "http://192.168.10.76:3000";
      this.host3d = host3d;
      let oldestUrl = "https://view.aismile.cn/?tx=https://picture.aismile.cn/";
      let oldUrl =
        this.environmene === "online"
          ? "https://view.test.aismile.cn/"
          : "http://view.test2.aismile.cn/";
      let newUrl = host3d;
      switch (toothViewVersion) {
        case "0":
          oldestUrl += toothView;
          break;
        case "1":
          if (this.caseDetail["cureType"] === "E") {
            const psTypeData = {
              attachmentFileType: this.cureNoteDetail["attachmentFileType"],
              attachmentList: this.cureNoteDetail["attachmentList"],
              cureType: this.caseDetail["cureType"]
            };
            oldUrl += `?t=${new Date().getTime()}&picPrefix=${
              this.$PicPrefix.split(".")[1]
            }&psTypeData=${encodeURI(
              JSON.stringify(psTypeData)
            )}&hideRight=1&data=${toothView}`;
          } else {
            oldUrl += `?t=${new Date().getTime()}&picPrefix=${
              this.$PicPrefix.split(".")[1]
            }&hideRight=1&data=${toothView}`;
          }
          break;
        case "2":
          const secret = "246e7ce1d443e219";
          const params = {
            origin: window.location.origin,
            env: this.environmene,
            showTabs: this.cureNoteDetail["cureType"] === "E",
            arches,
            themeType: this.themeDetail["themeType"]
          };
          const secretStr = this.encrypt(JSON.stringify(params), secret);
          newUrl += `/iframe/${encodeURIComponent(secretStr)}`;
          this.renderPlasticsAttInfo();
          break;
      }
      let animationView = {
        0: oldestUrl,
        1: oldUrl,
        2: newUrl
      }[toothViewVersion];
      this.iFrameSrc = animationView;
    },
    /** 计算数组指定下标间的总和 */
    sumArray(arr, startIndex, endIndex) {
      let sum = 0;
      for (let i = startIndex; i <= endIndex; i++) {
        sum += arr[i];
      }
      return sum;
    },
    /** 合并相邻批次间的数据，将上颌下颌数值相加 */
    mergeAdjacent(plastics, lower, upper) {
      var result = [];
      let diffentList = [];
      let lowerRes = [];
      let upperRes = [];
      plastics.forEach((item, index) => {
        if (item !== plastics[index - 1]) {
          result.push(item);
          diffentList.push(index);
        }
      });
      /**
       * 判断diffentList的长度，为1则表示所有批次一致直接取所有值的总和，反之取每次发生趋区间内的初始位和结束位的总和
       * 如：批次数组为['P00018', 'P00018', 'P00018', 'P00018', 'P00018', 'P00028']则diffentList为[0, 5];
       * startIndex 为区间内的初始下标，endIndex 为区间内的结束下标
       * 初始下标为每次循环的当前成员，结束下标为 i + 1 位成员 - 1；
       *  */
      if (diffentList.length === 1) {
        lowerRes.push(lower.reduce((acc, cur) => acc + cur, 0));
        upperRes.push(upper.reduce((acc, cur) => acc + cur, 0));
      } else {
        diffentList.forEach((item, index) => {
          let startIndex = diffentList[index];
          let endIndex = diffentList[index + 1] - 1;
          if (isNaN(endIndex)) {
            lowerRes.push(this.sumArray(lower, startIndex, lower.length - 1));
            upperRes.push(this.sumArray(upper, startIndex, upper.length - 1));
          } else {
            lowerRes.push(this.sumArray(lower, startIndex, endIndex));
            upperRes.push(this.sumArray(upper, startIndex, endIndex));
          }
        });
      }
      return {
        plasticsRes: result,
        lower: lowerRes,
        upper: upperRes
      };
    },
    renderBatch(jaw, all) {
      const obj = {
        start: [],
        end: []
      };
      jaw.forEach((it, idx) => {
        const endBatch = jaw.slice(0, idx + 1);
        const startBatch = jaw.slice(idx, jaw.length + 1);
        const endCount = +endBatch.reduce((a, b) => +a + +b);
        const startCount = all - +startBatch.reduce((a, b) => +a + +b) + 1;
        obj["start"].push(startCount);
        obj["end"].push(endCount);
      });
      return obj;
    },
    renderPlasticsAttInfo() {
      let renderProgressAtt = {};
      const {
        toothPreDownJawBatchCount,
        toothPreUpJawBatchCount,
        toothBatchPlasticSheet
      } = this.cureNoteDetail;
      const lowerList = toothPreDownJawBatchCount.split(",").map(Number);
      const upperList = toothPreUpJawBatchCount.split(",").map(Number);
      const plasticsList = toothBatchPlasticSheet.split(",");
      const lowerAllStep = eval(lowerList.join("+"));
      const upperAllStep = eval(upperList.join("+"));
      const { upper, lower, plasticsRes } = this.mergeAdjacent(
        plasticsList,
        lowerList,
        upperList
      );
      const lowerBatchObj = this.renderBatch(lower, lowerAllStep);
      const upperBatchObj = this.renderBatch(upper, upperAllStep);
      let finalPlasticsNodeList = [];
      if (lowerAllStep > upperAllStep) {
        finalPlasticsNodeList = lowerBatchObj["start"];
      } else {
        finalPlasticsNodeList = upperBatchObj["start"];
      }
      finalPlasticsNodeList.forEach((it, idx) => {
        renderProgressAtt[it] = {
          upper: {
            batch: `${
              upperBatchObj["end"][idx] < upperBatchObj["start"][idx]
                ? "0"
                : `${upperBatchObj["start"][idx]}-${upperBatchObj["end"][idx]}`
            }`,
            plastic: `${this.plasticSheetObj[plasticsRes[idx]]}`
          },
          lower: {
            batch: `${
              lowerBatchObj["end"][idx] < lowerBatchObj["start"][idx]
                ? "0"
                : `${lowerBatchObj["start"][idx]}-${lowerBatchObj["end"][idx]}`
            }`,
            plastic: `${this.plasticSheetObj[plasticsRes[idx]]}`
          }
        };
      });
      this.attInfo = renderProgressAtt;
    },
    encrypt(text, key) {
      const _key = cryptoJS.enc.Utf8.parse(key);
      return cryptoJS.AES.encrypt(text, _key, {
        mode: cryptoJS.mode.ECB,
        padding: cryptoJS.pad.Pkcs7
      }).toString();
    },
    send(params) {
      this.ortho.postMessage(params, this.host3d);
    },
    toComparePlan(id) {
      // if (id === this.nowCompareId) {
      //   return
      // }
      // this.nowCompareId = id;
      this.send({
        type: "compare",
        data: id
      });
    },
    load(data) {
      this.send({
        type: "load",
        data: data
      });
    },
    save(e, isAutoSave) {
      this.submitType = e;
      this.isAutoSave = isAutoSave;
      this.send({
        type: "save"
      });
    }
  },
  created() {
    this.init();
    // getQiniuToken().then((data) => {
    //   this.uploadToken = data;
    // });
  },
  mounted() {
    const {
      toothViewVersion,
      attachmentFileType,
      attachmentList
    } = this.cureNoteDetail;
    if (toothViewVersion === "2") {
      const div = document.getElementById("ortho");
      const _ortho = div?.contentWindow;
      this.ortho = _ortho;
      window.addEventListener("message", e => {
        if (e.origin !== this.host3d) {
          return null;
        }
        const { type, data } = e.data;
        switch (type) {
          // 动画初始化
          case "onPlayerInterfaceReady":
            if (this.comparePlanList.length) {
              this.send({
                type: "comparePlanList",
                data: this.comparePlanList
              });
            } else {
              this.send({
                type: "setComparable",
                data: false
              });
            }
            this.send({
              type: "themeData",
              data: this.themeDetail
            });
            this.send({
              type: "onModelChange",
              data: this.toothViewData
            });
            this.send({
              type: "material",
              data: this.attInfo
            });
            if (this.cureNoteDetail["cureType"] === "E") {
              this.send({
                type: "fixData",
                data: {
                  attachmentFileType,
                  attachmentList
                }
              });
            }
            break;
          case "onEditInterfaceReady":
            this.$emit("canCompare", true);
            if (Object.keys(this.modData).length) {
              this.load(this.modData["key"]);
              this.isLoaded = true;
            }
            if (this.buttonShow) {
              if (
                this.cureNoteDetail["toothViewVersion"] === "2" &&
                this.caseDetail["cureType"] !== "F" &&
                this.userDetail["mod3DFlag"] === "1"
              ) {
                this.$emit("canEdit", true);
                this.send({
                  type: "setEditable",
                  data: true
                });
              }
            } else {
              this.send({
                type: "setEditable",
                data: false
              });
              if (
                this.nowPort === "RDT" &&
                !this.amendmentShow &&
                "rdtMod3DId" in this.cureNoteDetail
              ) {
                this.$emit("canEdit", true);
              }
              if (
                ["DOCTOR", "SALES"].includes(this.nowPort) &&
                "doctorMod3DId" in this.cureNoteDetail &&
                this.cureNoteDetail["passFlag"] !== "0"
              ) {
                this.$emit("canEdit", true);
              }
            }
            break;
          case "btnEvent":
            if (data.type === "editing") {
              if (data.data) this.isEdit = true;
              this.send({
                type: "setEditing",
                data: data.data
              });
              this.$emit("editChange", data.data);
            }
            if (data.type === "compare") {
              this.send({
                type: "setCompare",
                data: data.data
              });
            }
            break;
          case "onEditingChange":
            this.$emit("editChange", data);
            break;
          case "onLog":
            this.$emit("toothLog", data);
            break;
          case "onSave":
            this.uploading = !this.isAutoSave;
            let nanoId = nanoid();
            const formData = new FormData();
            const blob = new Blob([data]);
            getQiniuToken().then(token => {
              this.uploadToken = token;
              formData.append("token", token);
              formData.append("file", blob);
              const caseNumber = this.caseDetail?.caseNumber;

              formData.append(
                "key",
                `3DFile/animationModification/temp/${caseNumber}/${nanoId}`
              );
              axios
                .post(`https://up.qiniu.com`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                })
                .then(res => {
                  const { data } = res;
                  this.uploading = false;
                  this.$emit("uploadingSuccess", {
                    etag: data.hash,
                    fileName: nanoId,
                    key: data.key,
                    mime: "multipart/form-data"
                  });
                });
            });
            break;
          case "themeData":
            this.$emit("onThemeChange", data);
            break;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.uploading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 99999;

  &-content {
    position: absolute;
    top: 136px;
    left: 50%;
    transform: translateX(-50%);
    width: 164px;
    height: 64px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333333;

    img {
      width: 56px;
      height: 56px;
      margin-right: 6px;
    }
  }
}

.orthobuilder {
  flex: 1;
  height: 100%;
}
</style>
