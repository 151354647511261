import {keys} from 'lodash';
export const adultTeethList =  [
  {number: 11,type: 'default'},
  {number: 12,type: 'default'},
  {number: 13,type: 'default'},
  {number: 14,type: 'default'},
  {number: 15,type: 'default'},
  {number: 16,type: 'default'},
  {number: 17,type: 'default'},
  {number: 18,type: 'default'},
  {number: 21,type: 'default'},
  {number: 22,type: 'default'},
  {number: 23,type: 'default'},
  {number: 24,type: 'default'},
  {number: 25,type: 'default'},
  {number: 26,type: 'default'},
  {number: 27,type: 'default'},
  {number: 28,type: 'default'},
  {number: 48,type: 'default'},
  {number: 47,type: 'default'},
  {number: 46,type: 'default'},
  {number: 45,type: 'default'},
  {number: 44,type: 'default'},
  {number: 43,type: 'default'},
  {number: 42,type: 'default'},
  {number: 41,type: 'default'},
  {number: 31,type: 'default'},
  {number: 32,type: 'default'},
  {number: 33,type: 'default'},
  {number: 34,type: 'default'},
  {number: 35,type: 'default'},
  {number: 36,type: 'default'},
  {number: 37,type: 'default'},
  {number: 38,type: 'default'},
];

export const youthDefaultBright = [
  {
    number: 55,
    type: 8
  },
  {
    number: 54,
    type: 8
  },
  {
    number: 53,
    type: 8
  },
  {
    number: 16,
    type: 8
  },
  {
    number: 12,
    type: 8
  },
  {
    number: 46,
    type: 8
  },
  {
    number: 42,
    type: 8
  },
  {
    number: 85,
    type: 8
  },
  {
    number: 84,
    type: 8
  },
  {
    number: 83,
    type: 8
  },
  {
    number: 63,
    type: 8
  },
  {
    number: 64,
    type: 8
  },
  {
    number: 65,
    type: 8
  },
  {
    number: 22,
    type: 8
  },
  {
    number: 26,
    type: 8
  },
  {
    number: 32,
    type: 8
  },
  {
    number: 36,
    type: 8
  },
  {
    number: 73,
    type: 8
  },
  {
    number: 74,
    type: 8
  },
  {
    number: 75,
    type: 8
  },
  {
    number: 11,
    type: 8
  },
  {
    number: 21,
    type: 8
  },
  {
    number: 41,
    type: 8
  },
  {
    number: 31,
    type: 8
  }
];
 
export const babyDefaultBright = [
  {
    number: 55,
    type: 8
  },{
    number: 54,
    type: 8
  },{
    number: 53,
    type: 8
  },{
    number: 52,
    type: 8
  },{
    number: 51,
    type: 8
  },{
    number: 65,
    type: 8
  },{
    number: 64,
    type: 8
  },{
    number: 63,
    type: 8
  },{
    number: 62,
    type: 8
  },{
    number: 61,
    type: 8
  },{
    number: 85,
    type: 8
  },{
    number: 84,
    type: 8
  },{
    number: 83,
    type: 8
  },{
    number: 82,
    type: 8
  },{
    number: 81,
    type: 8
  },{
    number: 75,
    type: 8
  },{
    number: 74,
    type: 8
  },{
    number: 73,
    type: 8
  },{
    number: 72,
    type: 8
  },{
    number: 71,
    type: 8
  },
]

export const yysdDefaultBright = [
  {number: 11,type: 'default'},
  {number: 12,type: 'default'},
  {number: 13,type: 'default'},
  {number: 14,type: 'default'},
  {number: 15,type: 'default'},
  {number: 16,type: 'default'},
  {number: 17,type: 'default'},
  {number: 18,type: 'default'},
  {number: 21,type: 'default'},
  {number: 22,type: 'default'},
  {number: 23,type: 'default'},
  {number: 24,type: 'default'},
  {number: 25,type: 'default'},
  {number: 26,type: 'default'},
  {number: 27,type: 'default'},
  {number: 28,type: 'default'},
  {number: 48,type: 'default'},
  {number: 47,type: 'default'},
  {number: 46,type: 'default'},
  {number: 45,type: 'default'},
  {number: 44,type: 'default'},
  {number: 43,type: 'default'},
  {number: 42,type: 'default'},
  {number: 41,type: 'default'},
  {number: 31,type: 'default'},
  {number: 32,type: 'default'},
  {number: 33,type: 'default'},
  {number: 34,type: 'default'},
  {number: 35,type: 'default'},
  {number: 36,type: 'default'},
  {number: 37,type: 'default'},
  {number: 38,type: 'default'},
]

export const adultOptions = {
  //8
  rightSagittalRelation: ['side_de_glaze','far_grind', 'bit_jump', 'tooth_extraction', 'after_design'],
  rightSagittalRelationExtraction: ['between_jaw', 'inside_jaw', 'tooth_extraction_nono'],

  leftSagittalRelation: ['side_de_glaze','far_grind', 'bit_jump', 'tooth_extraction', 'after_design'],
  leftSagittalRelationExtraction: ['between_jaw', 'inside_jaw', 'tooth_extraction_nono'],

  // 10
  overbiteInfo: ['press_back_tooth','extend_front_tooth','extend_front_press_back', 'press_front','press_front_rise_back','front_flat','other'],
  press_front_rise_back: ['upjaw', 'downjaw'],
  press_front: ['upjaw', 'downjaw'],

  //11
  frontToothInfo: ['uncorrect','correct_front_reverse', 'correct_front_opposite','front_tooth_unback','front_tooth_back','lip_up_front','tongue_down_front','lip_up_front_tongue_down_front','extend_up_front','extend_down_front'
  ],
  // 12
  backToothInfo: ['traction', 'resistance_nail', 'after_design']
};
export const adultOptionsKey = keys(adultOptions);

export const youthOptions = {
  // 13
  overbiteInfo: ['extend_front_tooth','press_back_tooth','extend_front_press_back','press_front','press_front_rise_back','other'],
  extend_front_tooth: ['upjaw', 'downjaw'],
  press_front: ['upjaw', 'downjaw'],
  press_front_rise_back: ['upjaw', 'downjaw'],

  //14
  frontToothInfo: ['front_tooth_unback','front_tooth_back','lip_up_front','tongue_down_front','lip_up_front_tongue_down_front']
};
export const youthOptionsKey = keys(youthOptions);

