<template>
  <div class="wrap">
    <p class="title_text" v-if="showTitle">
      {{ $t("casesDetail.casesChildren.casesToScan.yhmx") }}
    </p>

    <div class="content">
      <p class="fz14 main_theme_color_666">
        <span>{{
          details[renderModelType]
            ? $t("casesDetail.casesChildren.casesToScan.gxmx")
            : $t("cases.createImage.szmxwz")
        }}</span>
        <span class="ml10">{{
          details[renderModelType]
            ? details[renderModelType] === "self_deliver"
              ? $t("cases.createImage.yyyj")
              : $t("cases.createImage.zxyj")
            : {
                local_upload: $t("cases.createImage.bdsc"),
                iTero: "iTero",
                other: $t("cases.createImage.qt")
              }[details[renderFiled]]
        }}</span>
      </p>
      <div v-if="!details[renderModelType]" class="file_content">
        <div class="local_upload">
          <template v-if="details[renderFiled] === 'local_upload'">
            <div class="fz14 main_theme_color_333">
              {{ $t("cases.createImage.se") }}：{{
                get(
                  find(
                    fileList,
                    item =>
                      item.fileKey ===
                      (showTitle ? "sharp_upjaw" : "sharp_upjaw_remain")
                  ),
                  "fileName"
                )
              }}
            </div>
            <div class="mt1rem fz14 main_theme_color_333">
              {{ $t("cases.createImage.xe") }}：{{
                get(
                  find(
                    fileList,
                    item =>
                      item.fileKey ===
                      (showTitle ? "sharp_downjaw" : "sharp_downjaw_remain")
                  ),
                  "fileName"
                )
              }}
            </div>
          </template>

          <div
            class="fz14 main_theme_color_333"
            v-if="details[renderFiled] === 'iTero'"
          >
            {{ $t("cases.createImage.wj") }}：{{
              get(
                find(
                  fileList,
                  item =>
                    item.fileKey === (showTitle ? "iTero" : "iTero_remain")
                ),
                "fileName"
              )
            }}
          </div>

          <template v-if="details[renderFiled] === 'other'">
            <span class="fz14 main_theme_color_333"
              >{{ $t("cases.createImage.wj") }}：</span
            >
            <div
              v-for="item in filter(
                fileList,
                it =>
                  it.fileKey ===
                  (showTitle ? 'other_file' : 'other_file_remain')
              )"
              class="mb10 mt10 fz14 main_theme_color_333"
            >
              {{ get(item, "fileName") }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extend, find, filter, get } from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    details: {
      type: Object
    },
    fileList: {
      type: Array
    },
    renderModelType: {
      type: String,
      default: "siliconeRubberModel"
    },
    renderFiled: {
      type: String,
      default: "digitalModelFile"
    }
  },
  computed: {
    ...mapGetters({})
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.details);
      console.log(this.fileList);
    });
  },
  methods: {
    ...mapActions({}),
    find,
    filter,
    get
  },

  beforeDestroy() {},
  created() {}
};
</script>

<style lang="scss" scoped>
.wrap {
  .title_text {
    font-size: 14px;
    color: $main_theme_color_333;
  }

  .content {
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    display: flex;

    .file_content {
      width: 82%;
      height: 100%;
      margin-left: 52px;

      .local_upload {
        width: 100%;
        background-color: rgba(242, 242, 242, 1);
        padding: 10px 20px;
      }
    }
  }
}
</style>
