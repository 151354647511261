<template>
  <div class="feedback">
    <transition name="slide-fade">
      <div v-if="amendOpinion" class="revision-container">
        <div class="project-tabs" v-if="caseDetail['cureType'] === 'E'">
          <div
            :class="[nowProjectType === 1 ? 'left-active' : 'left-defualt']"
            @click="nowProjectType = 1"
          >
            {{ $t("threeD.jzfa") }}
          </div>
          <div
            :class="[nowProjectType === 2 ? 'right-active' : 'right-default']"
            @click="nowProjectType = 2"
          >
            {{ $t("threeD.xffa") }}
          </div>
          <div class="close-drawer" @click="amendOpinion = false"></div>
        </div>
        <div
          :class="
            caseDetail['cureType'] === 'E' && nowProjectType === 1
              ? 'revision-content-hide-radio'
              : 'revision-content'
          "
        >
          <div class="flex-content">
            <div class="cure-plan-name">
              <div
                class="user-name"
                :title="get(cureNoteDetail, 'userInfo.realName')"
              >
                {{ get(cureNoteDetail, "userInfo.realName") }}
              </div>
              <span>{{
                `${$t("casesDetail.casesDetail.tdzlfa")}${get(
                  cureNoteDetail,
                  "number"
                )}`
              }}</span>
            </div>
            <div class="create-design-time">
              {{ get(cureNoteDetail, "generateDatetime") }}
            </div>
            <div class="design-opinion">
              {{
                nowProjectType === 1
                  ? cureNoteDetail["note"]
                  : cureNoteDetail["veneerNote"]
              }}
            </div>
            <div class="divider-line"></div>
            <div class="opinion-description">
              <div class="opinion-description-title">
                <div class="flex-left">
                  <img src="@/common/imgs/3d/opinion_title.png" alt="" />
                  <p>{{ $t("threeD.yjms") }}</p>
                </div>
                <div class="flex-right" v-if="canEdit">
                  <div class="edit-3d-control" @click="changeViewType">
                    {{ isEdit ? $t("threeD.gbdhxg") : $t("threeD.threeddhxg") }}
                  </div>
                  <div class="help-icon" @click="toHelp"></div>
                </div>
              </div>
              <div class="mod-3d-record" v-if="mod3DRecord">
                <div class="record-tips">{{ $t("threeD.rjjzdjl") }}</div>
                <div class="record-info">{{ mod3DRecord }}</div>
              </div>
              <div class="opinion-description-content">
                <textarea
                  v-if="nowProjectType === 1"
                  v-model="operateRemark"
                  class="edit-revision"
                  :placeholder="$t('threeD.qzcsrjzfayj')"
                ></textarea>
                <textarea
                  v-if="nowProjectType === 2"
                  v-model="veneerRemark"
                  class="edit-revision"
                  :placeholder="$t('threeD.qzcsrxffayj')"
                ></textarea>
              </div>
              <p class="submit-tips">{{ $t("threeD.ntjd") }}</p>
              <p
                class="irdt-tips"
                v-if="nowPort === 'RDT' && cureNoteDetail.passFlag === '0'"
              >
                {{ $t("casesDetail.casesChildren.amendments.zcwrxtj") }}
              </p>
            </div>
          </div>
          <div class="flex-footer">
            <div
              v-if="nowPort === 'RDT'"
              @click="amendOpinion = false"
              class="working-area"
            >
              {{ $t("common.common.qx") }}
            </div>
            <div v-else @click="stagingData('0')" class="working-area">
              {{ $t("cases.casesCreate.zc") }}
            </div>
            <div
              @click="stagingData(nowPort === 'RDT' ? '0' : '2')"
              class="submit-revision"
            >
              {{
                nowPort === "RDT"
                  ? $t("cases.casesCreate.zc")
                  : $t("casesDetail.casesChildren.reviewPlan.tjxgyj")
              }}
            </div>
          </div>
        </div>
        <i
          @click="amendOpinion = false"
          v-if="caseDetail['cureType'] !== 'E'"
          class="el-icon-close close-revision"
        ></i>
      </div>
    </transition>
    <div class="feedback-content">
      <div class="user_message">
        <div
          class="user_pic"
          :style="{
            backgroundImage: `url(${$PicPrefix}${get(
              cureNoteDetail,
              'userInfo.photo'
            )})`
          }"
        ></div>
        <div class="user_content">
          <div class="user_name txt-ellipsis">
            {{ get(cureNoteDetail, "userInfo.realName") }}
          </div>
          <div class="user_age">
            <svg class="icon user_info_icon" aria-hidden="true">
              <use xlink:href="#icon-nianling"></use>
            </svg>
            <span class="mr10"
              >{{ get(cureNoteDetail, "userInfo.age") }}
              {{ $t("cases.cases.s") }}</span
            >
          </div>
        </div>
      </div>
      <p class="case-number">
        {{ $t("cases.cases.blh") }}：{{ get(caseDetail, "caseNumber") }}
      </p>
      <p class="curenote-name">
        {{
          schemeType === "target"
            ? $t("threeD.mbfa")
            : caseDetail["cureType"] === "F"
            ? $t("casesDetail.casesDetail.mxfa")
            : $t("casesDetail.casesDetail.tdzlfa")
        }}
        {{ cureNoteDetail["number"] }}
      </p>
      <p
        class="plastics-name"
        v-if="'toothBatchPlasticSheet' in cureNoteDetail"
      >
        {{ $t("threeD.mpcz") }}：{{ renderPlasticsNames() }}
        <span
          v-if="
            nowPort === 'DOCTOR' &&
              cureNoteDetail['passFlag'] === '0' &&
              ['to_doctor_confirm_plan'].includes(
                caseDetail['curePlanInfo'].status
              )
          "
          @click="callModalShow('edit')"
          class="edit-batch"
          >{{ $t("common.message.xg") }}</span
        >
        <span @click="callModalShow('info')" class="batch-info">{{
          $t("common.message.xq")
        }}</span>
      </p>
      <div class="project-tabs" v-if="caseDetail['cureType'] === 'E'">
        <div
          :class="[nowProjectType === 1 ? 'left-active' : 'left-default']"
          @click="nowProjectType = 1"
        >
          {{ $t("threeD.jzfa") }}
        </div>
        <div
          :class="[nowProjectType === 2 ? 'right-active' : 'right-default']"
          @click="nowProjectType = 2"
        >
          {{ $t("threeD.xffa") }}
        </div>
      </div>
      <div
        class="project-text"
        :class="[
          caseDetail['cureType'] !== 'E'
            ? 'default-project-text'
            : nowProjectType === 1
            ? 'left-active'
            : 'right-active'
        ]"
      >
        {{
          nowProjectType === 1
            ? cureNoteDetail["note"]
            : cureNoteDetail["veneerNote"]
        }}
      </div>
    </div>
    <div v-if="buttonShow" class="feedback-footer">
      <div
        v-if="amendmentShow"
        @click="amendOpinion = true"
        class="submit-revision"
      >
        {{ $t("casesDetail.casesChildren.reviewPlan.tjxgyj") }}
      </div>
      <div
        v-if="approveShow"
        @click="callModalShow('submit')"
        class="approval-programme"
      >
        {{
          schemeType === "target"
            ? $t("casesDetail.casesChildren.reviewPlan.qrcfa")
            : $t("casesDetail.casesChildren.reviewPlan.pzcfa")
        }}
      </div>
    </div>
    <div
      class="animation-change"
      v-if="
        nowPort === 'RDT' &&
          !amendmentShow &&
          'rdtMod3DId' in cureNoteDetail &&
          canEdit
      "
      @click="changeViewType"
    >
      {{ isEdit ? $t("threeD.gbdhxg") : $t("threeD.ckdhxg") }}
    </div>
    <div
      class="animation-change"
      @click="changeViewType"
      v-if="['SALES', 'DOCTOR'].includes(nowPort) && canEdit && !buttonShow"
    >
      {{ isEdit ? $t("threeD.gbdhxg") : $t("threeD.ckdhxg") }}
    </div>
    <transition name="slide-fade">
      <div v-if="showModal" class="modal-mask" @click="showModal = false">
        <div
          :class="
            !['edit', 'info'].includes(modalType)
              ? 'submit-modal'
              : 'plastics-modal'
          "
          @click="e => e.stopPropagation()"
        >
          <div class="modal-header">
            <i @click="showModal = false" class="el-icon-close close-modal"></i>
          </div>
          <div
            class="modal-content"
            v-if="['edit', 'info'].includes(modalType)"
          >
            <div class="bacth-current-title">
              <div class="label">{{ $t("braceReissue.mpzh") }}：</div>
              <div class="value">
                <div>
                  {{
                    `${$t("casesDetail.casesChildren.lookWearingRecord.ytzbs")}`
                  }}
                </div>
                <div>
                  {{
                    `${$t("cases.createDiagnosis.se")}：${
                      cureNoteDetail["correctStepUpjaw"]
                    }`
                  }}
                </div>
                <div>
                  {{
                    `${$t("cases.createDiagnosis.xe")}：${
                      cureNoteDetail["correctStepDownjaw"]
                    }`
                  }}
                </div>
              </div>
            </div>
            <div class="batch-content">
              <div
                class="batch-item"
                v-for="(item, index) in plasticsBatchList"
                :key="index"
              >
                <div>
                  {{
                    `${$t(
                      "casesDetail.casesChildren.lookWearingRecord.d"
                    )}${index + 1}${$t(
                      "casesDetail.casesChildren.lookWearingRecord.pc"
                    )}`
                  }}
                </div>
                <div>
                  {{
                    `${$t("cases.createDiagnosis.se")}：${
                      upperBatchList[index]
                    }`
                  }}
                </div>
                <div>
                  {{
                    `${$t("cases.createDiagnosis.xe")}：${
                      lowerBatchList[index]
                    }`
                  }}
                </div>
                <div v-if="modalType === 'edit'" class="plastics">
                  <span
                    >{{
                      $t("casesDetail.casesChildren.casesDoKeep.cz")
                    }}：</span
                  >
                  <el-select
                    class="sheet-select"
                    v-model="copyPlasticsBatchList[index]"
                    :placeholder="
                      $t('casesDetail.casesChildren.casesDoKeep.qxzcz')
                    "
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="(it, idx) in plasticsList"
                      :key="idx"
                      :value="it.sheetCode"
                      :label="it.name"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div v-else class="plastics">
                  {{
                    `${$t("casesDetail.casesChildren.casesDoKeep.cz")}：${
                      plasticSheetObj[plasticsBatchList[index]]
                    }`
                  }}
                </div>
              </div>
            </div>
            <div class="batch-tips">
              {{ `${$t("casesDetail.casesChildren.lookWearingRecord.cmpzh")}` }}
            </div>
          </div>
          <div v-else class="submit-content">
            <img
              src="https://picture.aismile.cn/web-static/aismile.cn/warn-dia-icon.png"
              alt=""
            />
            <div class="submit-title">
              {{ $t("casesDetail.casesChildren.reviewPlan.sfpz3d") }}
            </div>
            <div class="submit-tips">
              {{ `${$t("casesDetail.casesChildren.lookWearingRecord.rnpzh")}` }}
            </div>
            <div
              class="plastics-wrap"
              v-if="'toothBatchPlasticSheet' in cureNoteDetail"
            >
              <div class="plastics-content">
                <div class="label">{{ $t("braceReissue.mpzh") }}：</div>
                <div class="value" :title="renderPlasticsNames()">
                  {{ renderPlasticsNames() }}
                </div>
                <div class="control">
                  <span @click="callModalShow('edit')">{{
                    $t("common.message.xg")
                  }}</span
                  ><span @click="callModalShow('info')">{{
                    $t("common.message.xq")
                  }}</span>
                </div>
              </div>
              <div class="plastics-tips">
                {{
                  `${$t("casesDetail.casesChildren.lookWearingRecord.gsksjg")}`
                }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Button class="cancel" @click="showModal = false">
              {{ $t("common.message.qx") }}
            </Button>
            <Button class="confirm" @click="confirmModal">
              {{ confirmText[modalType] }}
            </Button>
          </div>
        </div>
      </div>
    </transition>
    <notifyMsgCm
      :isOldCase="get(cureNoteDetail, 'toothViewVersion') === '0'"
      ref="notifyMsgCm"
    />
  </div>
</template>

<script>
import notifyMsgCm from "../notify/notifyMsg";
import { extend, get } from "lodash";
import { confirmCurePlan, confirmTextPlan } from "common/api/cases";

export default {
  props: {
    environmene: String,
    cureNoteDetail: Object,
    caseDetail: Object,
    plasticsList: Array,
    plasticSheetObj: Object,
    diagnosisDetail: Object,
    userDetail: Object,
    mod3DRecord: String,
    canEdit: Boolean,
    isEdit: Boolean,
    defaultOperateRemark: String,
    defaultVeneerRemark: String,
    defaultAmendOpinion: Boolean
  },
  data() {
    return {
      nowProjectType: 1,
      amendOpinion: false,
      operateRemark: "",
      veneerRemark: "",
      showModal: false,
      modalType: "",
      confirmText: {
        edit: this.$t("casesDetail.casesDetail.bc"),
        info: this.$t("common.message.qd"),
        submit: this.$t("casesDetail.casesChildren.reviewPlan.rrpz")
      },
      upperBatchList: {},
      lowerBatchList: {},
      plasticsBatchList: [],
      copyPlasticsBatchList: [],
      materialData: [],
      autoSave: null,
      timer: 0
    };
  },
  watch: {
    veneerRemark(nval) {
      this.$emit("changeVeneerRemark", nval);
    },
    operateRemark(nval) {
      this.$emit("changeOperateRemark", nval);
    },
    amendOpinion(nval) {
      this.$emit("changeAmendOpinionShow", nval);
    }
  },
  components: {
    notifyMsgCm
  },
  computed: {
    buttonShow() {
      if (
        this.nowPort === "RDT" &&
        ["0", "2"].includes(this.cureNoteDetail["passFlag"])
      ) {
        return true;
      }
      if (
        ["SALES", "LIANSUO_CLINIC", "DOCTOR"].includes(this.nowPort) &&
        this.cureNoteDetail["passFlag"] === "0"
      ) {
        return true;
      }
    },
    approveShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      if (status == "to_doctor_confirm_plan" && this.nowPort === "DOCTOR") {
        return true;
      }
    },
    amendmentShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      const irdtCanShow = [
        "to_approve_plan",
        "to_reconfirm_plan",
        "to_confirm_plan"
      ];
      const canShow = ["to_doctor_confirm_plan"];
      if (
        this.nowPort === "RDT" &&
        irdtCanShow.includes(status) &&
        ["0", "2"].includes(this.cureNoteDetail["passFlag"]) &&
        this.cureNoteDetail["generateFlag"] == "1" &&
        this.cureNoteDetail["historyFlag"] == "0"
      ) {
        return true;
      }
      if (["SALES", "LIANSUO_CLINIC", "DOCTOR"].includes(this.nowPort)) {
        if (canShow.includes(status)) {
          return true;
        }
      }
    },
    schemeType() {
      return this.$route.query.schemeType;
    },
    irdtStage() {
      return this.$route.query.stage;
    },
    nowPort() {
      return this.userDetail["kind"] || "DOCTOR";
    }
  },
  methods: {
    get,
    toHelp() {
      window.open("/helpCenter?type=21");
    },
    renderBatchItems(jawList = [], allCount) {
      let list = [];
      jawList?.forEach((it, idx) => {
        const endBatch = jawList.slice(0, idx + 1);
        const startBatch = jawList.slice(idx, jawList.length + 1);
        const endCount = +endBatch.reduce((a, b) => +a + +b);
        const startCount = allCount - +startBatch.reduce((a, b) => +a + +b) + 1;
        list.push(endCount < startCount ? "0" : `${startCount}-${endCount}`);
      });
      return list;
    },
    callModalShow(type) {
      this.modalType = type;
      this.showModal = true;
    },
    renderPlasticsNames() {
      const result = this.plasticsBatchList.map(
        item => this.plasticSheetObj[item]
      );
      return Array.from(new Set(result)).join(" / ");
    },
    startAutoSave() {
      this.autoSave = setInterval(() => {
        this.stagingData("0", true);
      }, 30000);
    },
    stopAutoSave() {
      clearInterval(this.autoSave);
      this.autoSave = null;
    },
    changeViewType() {
      const _isEdit = !this.isEdit;
      this.$emit("changeViewType", _isEdit);
    },
    goPage(url) {
      let query = this.$route.query;
      sessionStorage.removeItem("hideHead");
      return (
        url &&
        this.$router.push({
          path: url,
          query: extend(
            {},
            { caseId: query.caseId, curePlanId: query.curePlanId }
          )
        })
      );
    },
    confirmModal() {
      if (["edit", "info"].includes(this.modalType)) {
        this.plasticsBatchList = [...this.copyPlasticsBatchList];
        this.showModal = false;
      } else {
        let api;
        this.schemeType === "target"
          ? (api = confirmTextPlan)
          : (api = confirmCurePlan);
        const curePlanId = this.$route.query.curePlanId;
        if (!curePlanId) {
          this.showModal = false;
          return false;
        }
        const isExistData = this.materialData.filter(item =>
          this.copyPlasticsBatchList.includes(item.sheetCode)
        );
        const differentData = Array.from(new Set(this.copyPlasticsBatchList));
        if (isExistData.length !== differentData.length) {
          return this.$message({
            message: this.$t("threeD.mpczbczczlb"),
            type: "warning"
          });
        }
        api({
          id: curePlanId,
          approveResult: "1",
          toothBatchPlasticSheet: this.copyPlasticsBatchList.join(",")
        })
          .then(() => {
            this.showModal = false;
            this.$refs["notifyMsgCm"]
              .open({
                text: this.$t("casesDetail.casesChildren.common.czcg"),
                type: "success"
              })
              .then(() => {
                setTimeout(() => {
                  const { userInfo } = this.cureNoteDetail;
                  localStorage.setItem(
                    "windowName",
                    userInfo.realName + this.$t("common.common.blxq")
                  );
                  let query = this.$route.query;
                  this.goPage(
                    `/cases/detail?caseId=${query.caseId}&curePlanId=${query.curePlanId}`
                  );
                }, 1000);
              });
          })
          .catch(() => {
            this.showModal = false;
          });
      }
    },
    // 暂存 or 提交修改意见
    stagingData(type, isAutoSave = false) {
      let { ts, token } = this.$route.query;
      let obj = {
        type,
        approveResult: type,
        token
      };
      if (type !== "0") {
        if (this.operateRemark == "" && this.veneerRemark == "") {
          if (this.caseDetail["cureType"] === "E") {
            return this.$refs["notifyMsgCm"].open({
              text: this.$t("threeD.qzsxtyxfajxtj"),
              type: "warning"
            });
          } else {
            return this.$refs["notifyMsgCm"].open({
              text: this.$t("casesDetail.casesChildren.amendments.qxtx"),
              type: "warning"
            });
          }
        }
      }
      if (this.operateRemark !== "") {
        obj.suggest = this.operateRemark;
      } else {
        obj.suggest = "";
      }
      if (this.veneerRemark !== "") {
        obj.veneerSuggest = this.veneerRemark;
      } else {
        obj.veneerSuggest = "";
      }
      if (!ts) {
        obj.toothBatchPlasticSheet = this.copyPlasticsBatchList.join(",");
      }
      this.$emit("stagingData", obj, isAutoSave);
    }
  },
  mounted() {
    this.materialData = this.plasticsList;
    if ("toothBatchPlasticSheet" in this.cureNoteDetail) {
      const list = this.cureNoteDetail["toothBatchPlasticSheet"];
      this.plasticsBatchList = list?.split(",");
      this.copyPlasticsBatchList = [...this.plasticsBatchList];
      const {
        toothPreDownJawBatchCount,
        toothPreUpJawBatchCount,
        correctStepDownjaw,
        correctStepUpjaw,
        suggest = "",
        veneerSuggest = ""
      } = this.cureNoteDetail;
      this.operateRemark =
        this.defaultOperateRemark !== "" ? this.defaultOperateRemark : suggest;
      this.veneerRemark =
        this.defaultOperateRemark !== ""
          ? this.defaultVeneerRemark
          : veneerSuggest;
      this.amendOpinion = this.defaultAmendOpinion;
      const lower = toothPreDownJawBatchCount.split(",");
      const upper = toothPreUpJawBatchCount.split(",");
      this.lowerBatchList = this.renderBatchItems(lower, correctStepDownjaw);
      this.upperBatchList = this.renderBatchItems(upper, correctStepUpjaw);
    } else {
      this.plasticsBatchList = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.feedback {
  height: 100%;
  max-width: 444px;
  padding: 44px 30px 30px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  color: var(--textColor);
  position: relative;
  overflow: hidden;

  .revision-container {
    position: absolute;
    z-index: 8;
    top: 24px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    background-color: var(--feedback-message-background);
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .project-tabs {
      height: 40px;
      display: flex;
      background-color: var(--darkColor);
      border-radius: 4px 4px 0 0;

      > div {
        font-size: 14px;
        cursor: pointer;
        color: var(--textColor);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .left-active {
        background: var(--feedback-tabs-active-left-theme-imgs) no-repeat center;
        transform: translateY(1px) translateX(0.2px);
        background-size: 100% 100%;
        color: #fcc80e;
      }

      .right-active {
        background: var(--feedback-tabs-active-right-theme-imgs) no-repeat
          center;
        transform: translateY(1px);
        background-size: 100% 100%;
        color: #fcc80e;
      }

      .close-drawer {
        cursor: pointer;
        flex: none;
        width: 74px;
        height: 40px;
        background: var(--close-drawer-img) no-repeat center;
        background-size: 16px 16px;
      }
    }

    .close-revision {
      font-size: 24px;
      position: absolute;
      top: 12px;
      right: 20px;
      cursor: pointer;
    }

    .revision-content,
    .revision-content-hide-radio {
      flex: 1;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      color: var(--textColor);
      word-break: break-all;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .flex-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 30px;

        .cure-plan-name {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;

          .user-name {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 20px;
            line-height: 28px;
          }

          span {
            font-size: 14px;
            line-height: 20px;
            color: #fcc80e;
            margin-left: 7px;
          }
        }

        .create-design-time {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .design-opinion {
          width: 100%;
          height: 180px;
          border-radius: 4px;
          border: 1px solid #bbbbbb;
          background-color: var(--backgroundColor);
          padding: 10px 16px;
          font-size: 14px;
          line-height: 20px;
          overflow-y: auto;
          flex-shrink: 0;
          white-space: pre-wrap;
        }

        .divider-line {
          width: 100%;
          height: 1px;
          background-color: #bbbbbb;
          margin: 30px 0;
          flex-shrink: 0;
        }

        .opinion-description {
          &-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
            justify-content: space-between;
            position: relative;

            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            .flex-left,
            .flex-right {
              display: flex;
              align-items: center;
            }

            .edit-3d-control {
              min-width: 104px;
              text-align: center;
              cursor: pointer;
              justify-self: flex-end;
              height: 28px;
              line-height: 28px;
              padding: 0 8px;
              border-radius: 4px;
              background-color: #fcc80e;
              color: #333333;
              font-size: 14px;
            }

            .help-icon {
              cursor: pointer;
              width: 16px;
              height: 16px;
              background: var(--help-icon) no-repeat center;
              background-size: 100% 100%;
              margin-left: 4px;
            }
          }

          .mod-3d-record {
            margin-top: 10px;

            .record-tips {
              font-size: 14px;
              color: var(--messageColor);
              line-height: 20px;
              margin-bottom: 4px;
            }

            .record-info {
              font-size: 14px;
              line-height: 20px;
              color: var(--textColor);
              white-space: pre-wrap;
            }
          }

          &-content {
            .edit-revision {
              box-sizing: border-box;
              margin-top: 10px;
              width: 100%;
              height: 180px;
              font-size: 14px;
              line-height: 20px;
              border-radius: 4px;
              padding: 10px 16px;
              border: 1px solid #bbbbbb;
              transition: linear 0.2s;

              &:focus {
                border: 1px solid #fcc80e;
              }
            }
          }

          .submit-tips,
          .irdt-tips {
            font-size: 14px;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }

      .flex-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        width: 100%;
        padding: 0 30px 30px;
        height: 36px;

        .working-area,
        .submit-revision {
          cursor: pointer;
          flex: 1;
          max-width: 156px;
          height: 36px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #333333;
        }

        .working-area {
          border: 1px solid #bbbbbb;
          background-color: #ffffff;
        }

        .submit-revision {
          margin-left: 32px;
          background-color: #fcc80e;
        }
      }
    }

    .revision-content-hide-radio {
      border-radius: 0px 4px 4px 4px;
    }
  }

  .feedback-content {
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .user_message {
      width: 100%;
      height: 88px;
      display: flex;
      margin-bottom: 30px;

      .user_pic {
        width: 0.88rem;
        height: 0.88rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 0.19rem;
        border-radius: 6px;
      }

      .user_content {
        flex: 1;

        .user_name {
          width: 230px;
          height: 25px;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 12px;
          color: var(--textColor);
        }

        .user_age {
          font-size: 14px;
          color: var(--messageColor);
          display: flex;
          align-items: center;

          span {
            margin-top: 2px;
          }
        }

        .user_info_icon {
          margin-left: -2px;
          width: 24px;
          height: 26px;
        }
      }
    }

    .case-number,
    .curenote-name,
    .plastics-name {
      color: var(--textColor);
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    .plastics-name {
      margin-bottom: 26px;

      .batch-info,
      .edit-batch {
        cursor: pointer;
        color: #fcc80e;
      }

      .edit-batch {
        margin: 0 12px;
      }
    }

    .project-tabs {
      height: 40px;
      display: flex;
      background-color: var(--darkColor);
      border-radius: 4px 4px 0 0;

      > div {
        font-size: 14px;
        cursor: pointer;
        color: var(--textColor);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .left-active {
        background: var(--feedback-tabs-active-left-theme-imgs) no-repeat center;
        transform: translateY(1px);
        background-size: 100% 100%;
        color: var(--mainColor);
      }

      .right-active {
        background: var(--feedback-tabs-active-right-theme-imgs) no-repeat
          center;
        transform: translateY(1px);
        background-size: 100% 100%;
        color: var(--mainColor);
      }
    }

    .project-text {
      flex: 1;
      min-height: 200px;
      overflow: hidden auto;
      word-break: break-all;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 22px;
      padding: 16px;
      border: 1px solid #bbb;
      background-color: var(--feedback-message-background);
    }

    .default-project-text {
      border-radius: 4px;
    }
  }

  .feedback-footer {
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .submit-revision,
    .approval-programme {
      cursor: pointer;
      flex: 1;
      max-width: 156px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      border-radius: 6px;
      font-size: 16px;
      color: #333333;
    }

    .submit-revision {
      background-color: #ffffff;
      border: 1px solid #bbbbbb;
    }

    .approval-programme {
      margin-left: 32px;
      background-color: var(--mainColor);
      color: #333333;
    }
  }

  .animation-change {
    cursor: pointer;
    margin-top: 24px;
    width: 176px;
    height: 42px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
  }

  .slide-fade-enter-active {
    transition: all 0.1s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(5px);
    opacity: 0;
  }
}

.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .plastics-modal,
  .submit-modal {
    width: 860px;
    height: 468px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #828282;
    background-color: var(--darkColor);

    .modal-header {
      padding: 0 20px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .close-modal {
        cursor: pointer;
        font-size: 24px;
      }
    }

    .modal-content {
      flex: 1;
      padding: 30px;
      overflow-y: auto;
      word-break: break-all;

      .bacth-current-title {
        display: flex;
        align-items: center;
        color: var(--textColor);
        font-size: 14px;
        line-height: 20px;

        .label {
          width: 100px;
          margin-right: 10px;
        }

        .value {
          display: flex;
          align-items: center;

          div {
            width: 135px;
            margin-right: 16px;
          }
        }
      }

      .batch-content {
        margin-top: 26px;
        padding-left: 110px;

        .batch-item {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          color: var(--textColor);

          div {
            width: 135px;
            margin-right: 16px;
          }

          .plastics {
            flex: 1;
            margin-right: 0;
          }

          .plastics-select {
            width: 148px;

            :global {
              .ant-select-selector {
                font-size: 14px;
                width: 100%;
              }
            }
          }
        }
      }

      .batch-tips {
        font-size: 12px;
        padding-left: 80px;
        line-height: 20px;
        color: var(--messageColor);
      }
    }

    .submit-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      img {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;
      }

      .submit-title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .submit-tips {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        text-align: center;
        color: var(--textColor);
      }

      .plastics-wrap {
        width: 420px;
        min-height: 96px;
        border-radius: 8px;
        background-color: var(--backgroundColor);
        padding: 12px;
        backdrop-filter: blur(0px);

        .plastics-content {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 20px;

          > div {
            flex-shrink: 0;
          }

          .label {
            max-width: 80px;
            margin-right: 20px;
          }

          .value {
            flex: 1;
            margin: 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .control {
            color: var(--mainColor);

            span {
              cursor: pointer;
            }

            :first-child {
              margin-right: 8px;
            }
          }
        }

        .plastics-tips {
          color: var(--messageColor);
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .modal-footer {
      height: 92px;
      display: flex;
      justify-content: center;
      align-items: center;

      .cancel,
      .confirm {
        cursor: pointer;
        min-width: 124px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #333333;
        font-size: 14px;
        background-color: #fcc80e;
        background-color: var(--mainColor);
      }

      .cancel {
        background-color: #ffffff;
        border: 1px solid #bbbbbb;
        margin-right: 32px;
      }
    }
  }

  .submit-modal {
    width: 500px;
    height: 368px;
  }
}

/deep/ .sheet-select {
  width: 148px !important;
}

.plastics /deep/ .input::-webkit-input-placeholder {
  color: var(--messageColor);
  background-color: var(--backgroundColor);
}

.plastics /deep/ .el-select,
.plastics /deep/ .el-input__inner {
  background-color: var(--backgroundColor) !important;
  color: var(--textColor);
}

.plastics /deep/ .el-input .el-input--suffix {
  border-radius: 10px;
  //background: #58595A;
  background: red !important;
}

.plastics /deep/ .el-select-dropdown__wrap {
  background-color: var(--backgroundColor);
  border: none;
}

.plastics /deep/ .el-scrollbar {
  border-radius: 12px;
  background-color: var(--backgroundColor);
  border: none;
}

.plastics /deep/ .el-select-dropdown__item {
  color: var(--textColor) !important;
  z-index: 9999;
}

.plastics /deep/ .el-select-dropdown__list {
  background-color: var(--backgroundColor);
}

.plastics /deep/ .el-select-dropdown {
  background-color: var(--backgroundColor);

  border: none;
}

.plastics /deep/ .el-select-dropdown__item.hover {
  background: var(--darkColor) !important;
  color: #fcc80e !important;
}

.plastics /deep/ .popper__arrow::after {
  color: var(--darkColor);
  border-bottom-color: var(--backgroundColor);
}

.plastics /deep/ .el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: var(--backgroundColor);
}
</style>
