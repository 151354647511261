<template>
  <div class="dentition-space">
    <div v-if="cureType === 'C'" class="upjaw-container">
      <div
        v-for="(item, index) in teethOne"
        :key="index"
        :class="[
          selectTeethFn(item.number, selectOne)
            ? 'jaw-item'
            : 'jaw-item-disabled',
          item.width && 'jaw-item-changed'
        ]"
      >
        <span>{{ item.number }}</span>
        <el-input
          v-model="item.width"
          :disabled="readOnly || !selectTeethFn(item.number, selectOne)"
          :value="item.width"
          class="space-input"
          type="text"
          @change="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectOne
            })
          "
          @input="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectOne
            })
          "
        />
      </div>
    </div>
    <div v-else class="upjaw-container">
      <div
        v-for="(item, index) in teethTow"
        :key="index"
        :class="[
          selectTeethFn(item.number, selectTow)
            ? 'jaw-item'
            : 'jaw-item-disabled',
          item.width && 'jaw-item-changed'
        ]"
      >
        <span>{{ item.number }}</span>
        <el-input
          v-model="item.width"
          :disabled="readOnly || !selectTeethFn(item.number, selectTow)"
          :value="item.width"
          class="space-input"
          type="text"
          @change="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectTow
            })
          "
          @input="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectTow
            })
          "
        />
      </div>
    </div>
    <div v-if="cureType === 'C'" class="lowerjaw-container">
      <div
        v-for="(item, index) in teethFour"
        :key="index"
        :class="[
          selectTeethFn(item.number, selectFour)
            ? 'jaw-item'
            : 'jaw-item-disabled',
          item.width && 'jaw-item-changed'
        ]"
      >
        <span>{{ item.number }}</span>
        <el-input
          v-model="item.width"
          :disabled="readOnly || !selectTeethFn(item.number, selectFour)"
          :value="item.width"
          class="space-input"
          type="text"
          @change="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectFour
            })
          "
          @input="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectFour
            })
          "
        />
      </div>
    </div>
    <div v-else class="lowerjaw-container">
      <div
        v-for="(item, index) in teethThree"
        :key="index"
        :class="[
          selectTeethFn(item.number, selectThree)
            ? 'jaw-item'
            : 'jaw-item-disabled',
          item.width && 'jaw-item-changed'
        ]"
      >
        <span>{{ item.number }}</span>
        <el-input
          v-model="item.width"
          :disabled="readOnly || !selectTeethFn(item.number, selectThree)"
          :value="item.width"
          class="space-input"
          type="text"
          @change="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectThree
            })
          "
          @input="
            disposeNumber({
              key: item,
              value: arguments[0],
              selectList: selectThree
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  filter,
  map,
  isArray,
  each,
  get,
  pickBy,
  extend,
  isNumber
} from "lodash";
import { toNumber } from "@/common/js/Fn";
import { mapState } from "vuex";

export default {
  data() {
    return {
      teethOne: [
        {
          number: 55
        },
        {
          number: 54
        },
        {
          number: 53
        },
        {
          number: 52
        },
        {
          number: 51
        },
        {
          number: 61
        },
        {
          number: 62
        },
        {
          number: 63
        },
        {
          number: 64
        },
        {
          number: 65
        }
      ],
      teethTow: [
        {
          number: 18
        },
        {
          number: 17
        },
        {
          number: 16
        },
        {
          number: 15
        },
        {
          number: 14
        },
        {
          number: 13
        },
        {
          number: 12
        },
        {
          number: 11
        },
        {
          number: 21
        },
        {
          number: 22
        },
        {
          number: 23
        },
        {
          number: 24
        },
        {
          number: 25
        },
        {
          number: 26
        },
        {
          number: 27
        },
        {
          number: 28
        }
      ],
      teethThree: [
        {
          number: 48
        },
        {
          number: 47
        },
        {
          number: 46
        },
        {
          number: 45
        },
        {
          number: 44
        },
        {
          number: 43
        },
        {
          number: 42
        },
        {
          number: 41
        },
        {
          number: 31
        },
        {
          number: 32
        },
        {
          number: 33
        },
        {
          number: 34
        },
        {
          number: 35
        },
        {
          number: 36
        },
        {
          number: 37
        },
        {
          number: 38
        }
      ],
      teethFour: [
        {
          number: 85,
          spacingType: ""
        },
        {
          number: 84,
          spacingType: ""
        },
        {
          number: 83,
          spacingType: ""
        },
        {
          number: 82,
          spacingType: ""
        },
        {
          number: 81,
          spacingType: ""
        },
        {
          number: 71,
          spacingType: ""
        },
        {
          number: 72,
          spacingType: ""
        },
        {
          number: 73,
          spacingType: ""
        },
        {
          number: 74,
          spacingType: ""
        },
        {
          number: 75,
          spacingType: ""
        }
      ]
    };
  },
  props: {
    readOnly: {
      type: Boolean
    },
    commonTeeth: {
      type: Array
    },
    uploadIntervalList: {
      type: Array
    },
    cureType: {
      type: String
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    selectList() {
      return map(
        filter(this.commonTeeth, item => item.number && !item.deficiency),
        it => it.number
      );
    },
    intervalList() {
      return this.uploadIntervalList
        ? JSON.parse(JSON.stringify(this.uploadIntervalList))
        : [];
    },
    selectOne() {
      let list = filter(this.selectList, item =>
        [55, 54, 53, 52, 51, 61, 62, 63, 64, 65].includes(item)
      );
      let leftTooth = filter(list, item => item >= 51 && item <= 55).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, item => item >= 61 && item <= 65).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectTow() {
      let list = filter(this.selectList, item =>
        [
          18,
          17,
          16,
          15,
          14,
          13,
          12,
          11,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28
        ].includes(item)
      );
      let leftTooth = filter(list, item => item >= 11 && item <= 18).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, item => item >= 21 && item <= 28).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectThree() {
      let list = filter(this.selectList, item =>
        [
          48,
          47,
          46,
          45,
          44,
          43,
          42,
          41,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38
        ].includes(item)
      );
      let leftTooth = filter(list, item => item >= 41 && item <= 48).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, item => item >= 31 && item <= 38).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectFour() {
      let list = filter(this.selectList, item =>
        [85, 84, 83, 82, 81, 71, 72, 73, 74, 75].includes(item)
      );
      let leftTooth = filter(list, item => item >= 81 && item <= 85).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, item => item >= 71 && item <= 75).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    totalTeeth() {
      if (this.cureType !== "C") {
        return [...this.teethOne, this.teethFour];
      } else {
        return [...this.teethTow, this.teethThree];
      }
    }
  },
  mounted() {
    this.initList(this.uploadIntervalList);
  },
  watch: {
    uploadIntervalList: function(nval) {
      this.initList(nval);
    }
  },
  methods: {
    find,
    initList(arr) {
      let list = ["teethOne", "teethTow", "teethThree", "teethFour"];
      if (arr.length) {
        each(list, listKey => {
          this[listKey] = map(this[listKey], item => {
            let obj = item;
            each(arr, it => {
              if (item.number === it.number) {
                return (obj = extend({}, item, it));
              }
            });
            return obj;
          });
        });
      }
    },
    // 输入框只能输入数字
    disposeNumber({ key, value, selectList }) {
      if (value === "") {
        delete key.type;
        delete key.nextNumber;
        return;
      }
      const isValidNumber = /^(\d*\.?\d{0,2})$/.test(value);
      const result = isValidNumber ? (value > 25 ? 25 : value) : "";
      key.width = result;
      // key.width =
      //   isNumber(toNumber(value)) && toNumber(value) <= 25
      //     ? toNumber(value)
      //     : "";

      key.type = "15";
      key.nextNumber = this.findAdjoinTooth(key.number, selectList);
      this.$emit(
        "updateInterval",
        filter(
          this.cureType === "C"
            ? [...this.teethOne, ...this.teethFour]
            : [...this.teethTow, ...this.teethThree],
          item => item.width
        )
      );
    },
    selectTeethFn(number, list) {
      const boolean =
        list.indexOf(number) !== -1 && this.findAdjoinTooth(number, list);
      return boolean;
    },
    findAdjoinTooth(number, list) {
      let index = list.indexOf(number);
      return list[index + 1];
    },
    clearTeeth() {
      each(["teethOne", "teethTow", "teethThree", "teethFour"], list => {
        this[list] = map(this[list], item => {
          return {
            number: item.number
          };
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dentition-space {
  display: flex;
  flex-direction: column;
  align-items: center;

  .upjaw-container,
  .lowerjaw-container {
    display: flex;
    align-items: flex-end;
    height: 60px;

    .jaw-item,
    .jaw-item-disabled {
      padding: 4px 20px;
      border-right: 1px solid #e5e5e5;
      position: relative;

      .space-input {
        right: 0;
        top: -8px;
        transform: translate(50%, -100%);
      }

      span {
        font-size: 14px;
        line-height: 20px;
        color: #999999;
      }

      &:last-child {
        border-right: none;

        .space-input {
          display: none;
        }
      }
    }
  }

  .lowerjaw-container {
    width: fit-content;
    align-items: flex-start;
    padding-top: 8px;
    margin: 8px 0 20px;
    border-top: 1px dashed #e5e5e5;

    .space-input {
      bottom: -8px;
      top: unset !important;
      transform: translate(50%, 100%) !important;
    }
  }

  /deep/ .space-input {
    position: absolute;
    width: 48px;
    height: 24px;

    .el-input__inner {
      width: 48px;
      height: 24px;
      padding: 0 0px;
      text-align: center;
    }
  }

  /deep/ .jaw-item-disabled {
    .space-input {
      .el-input__inner {
        background-color: rgba($color: #bbbbbb, $alpha: 0.3) !important;
      }
    }
  }

  /deep/ .jaw-item-changed {
    .space-input {
      .el-input__inner {
        color: #fcc80e;
        border: 1px solid #fcc80e;
        background-color: rgba($color: #ffffff, $alpha: 0.3) !important;
      }
    }
  }
}
</style>
