<template>
  <div class="init-notice">
    <div
      class="init-notice-content"
      :style="{ height: language == 'en_US' ? '6rem' : '5.6rem' }"
    >
      <div class="left-navigation">
        <div class="navigation-title">{{ `${$t("notice.threeD.gxnr")}` }}</div>
        <div
          v-if="hasEditViewControl"
          :class="
            activeKey !== 2 ? 'navigation-item-active' : 'navigation-item'
          "
          @click="activeKey = 0"
        >
          {{ `${$t("notice.threeD.dhxg")}` }}
        </div>
        <div
          :class="
            activeKey === 2 ? 'navigation-item-active' : 'navigation-item'
          "
          @click="activeKey = 2"
        >
          {{ `${$t("notice.threeD.bjsz")}` }}
        </div>
      </div>
      <div class="right-content">
        <div class="close-notice" @click="noPrompt"></div>
        <div
          class="content-item"
          v-for="(item, index) in contentWraps"
          v-if="item.canShow"
          :key="index"
          :style="{ display: activeKey === index ? 'flex' : 'none' }"
        >
          <div :class="`img${index + 1}`"></div>
          <div class="message-content">
            <div class="title">
              {{ item.title }}
              <div class="step-wrap" v-if="hasEditViewControl">
                {{ `${index + 1} / ${contentWraps.length}` }}
              </div>
            </div>
            <div v-if="index === 1">
              <div class="message-item">
                {{ `${$t("notice.threeD.djyctjxgyj")}` }}
              </div>
              <div class="message-item">
                {{ `${$t("notice.threeD.jtdhxggnjs")}` }}

                <span @click="toHelp" class="link">
                  {{ `${$t("notice.threeD.dhxgczbz")}` }}
                  <i
                    class="el-icon-arrow-right
"
                  ></i
                ></span>
              </div>
            </div>
            <div
              class="message-item"
              v-for="(cItem, cIndex) in item.message"
              :key="cIndex"
            >
              {{ cItem }}
            </div>
            <div class="step-controls">
              <div
                @click="prevStep"
                v-if="hasEditViewControl"
                :class="activeKey === 0 ? 'prev-step-disabled' : 'prev-step'"
              >
                {{ `${$t("notice.threeD.syg")}` }}
              </div>
              <div v-else></div>
              <div @click="nextStep" class="next-step">
                {{
                  activeKey === 3
                    ? `${$t("notice.threeD.msty")}`
                    : `${$t("notice.threeD.xyg")}`
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeLocalStorageNoticeData } from "@/common/js/util.js";
import { mapState } from "vuex";

export default {
  props: {
    hasEditViewControl: Boolean,
    noticeKey: Object
  },
  data() {
    return {
      activeKey: this.hasEditViewControl ? 0 : 2,
      navigators: [
        this.$t("notice.threeD.dhxg"),
        this.$t("notice.threeD.bjsz")
      ],
      contentWraps: [
        {
          img: "--view3d-init-notice-bg1",
          title: this.$t("notice.threeD.dhxg"),
          message: [
            this.$t("notice.threeD.yskdsjfa"),
            this.$t("notice.threeD.xgnrhxgyj")
          ],
          canShow: this.hasEditViewControl
        },
        {
          img: "--view3d-init-notice-bg2",
          title: this.$t("notice.threeD.dhxg"),
          canShow: this.hasEditViewControl
        },
        {
          img: "--view3d-init-notice-bg3",
          title: this.$t("notice.threeD.bjsz"),
          message: [this.$t("notice.threeD.yskytjdh")],
          canShow: true
        }
      ]
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  methods: {
    toHelp() {
      window.open("/helpCenter?type=21");
    },
    noPrompt() {
      changeLocalStorageNoticeData(this.noticeKey, false);
      this.$emit("closeDrawer");
    },
    nextStep() {
      if (this.activeKey === 2) {
        this.noPrompt();
      } else {
        this.activeKey += 1;
      }
    },
    prevStep() {
      if (this.activeKey === 0) return;
      this.activeKey -= 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.init-notice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.4);
  z-index: 999;

  &-content {
    width: 852px;
    height: 560px;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-color: var(--view3d-init-notice-bgcolor);
    display: flex;

    .left-navigation {
      display: flex;
      flex-direction: column;
      width: 192px;
      padding: 16px 0;
      background-color: var(--view3d-init-notice-left-bgcolor);

      .navigation-title {
        padding-left: 20px;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 16px;
        color: var(--textColor);
      }

      .navigation-item,
      .navigation-item-active {
        color: var(--textColor);
        cursor: pointer;
        position: relative;
        padding-left: 32px;
        height: 48px;
        display: flex;
        align-items: center;
        font-size: 16px;
      }

      .navigation-item-active {
        background: var(--view3d-init-notice-left-active-item);
        color: var(--mainColor);

        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 24px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: var(--mainColor);
        }
      }
    }

    .right-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .content-item {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .close-notice {
        cursor: pointer;
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        right: 20px;
        top: 20px;
        background: var(--view3d-close-notice) no-repeat center;
        background-size: 100% 100%;
      }

      .img1,
      .img2,
      .img3 {
        width: 660px;
        height: 370px;
      }

      .img1 {
        background: var(--view3d-init-notice-bg1) no-repeat center;
        background-size: 100% 100%;
      }

      .img2 {
        background: var(--view3d-init-notice-bg2) no-repeat center;
        background-size: 100% 100%;
      }

      .img3 {
        background: var(--view3d-init-notice-bg3) no-repeat center;
        background-size: 100% 100%;
      }

      .message-content {
        position: relative;
        box-sizing: border-box;
        flex: 1;
        border-left: 1px solid
          var(--view3d-init-notice-message-content-border-color);
      }

      .title {
        font-size: 18px;
        color: var(--textColor);
        line-height: 26px;
        margin: 16px 0 8px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .step-wrap {
          width: 48px;
          height: 24px;
          border-radius: 12px;
          background: rgba(255, 255, 255, 0.16);
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .message-item {
        padding: 0 16px;
        font-size: 14px;
        line-height: 28px;
        color: var(--textColor);

        .link {
          cursor: pointer;
          color: var(--mainColor);
        }
      }

      .step-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;

        .next-step,
        .prev-step,
        .prev-step-disabled {
          user-select: none;
          cursor: pointer;
          height: 36px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: var(--mainColor);
        }

        .prev-step-disabled {
          cursor: not-allowed;
          color: #999999;
        }

        .next-step {
          min-width: 96px;
          padding: 0 20px;
          color: #333333;
          background-color: var(--mainColor);
        }
      }
    }
  }
}
</style>
